export default {
  SPACE_MANAGEMENT: "companies/:company_id/space-management/",
  PROPERTY_AMENITIES: "companies/:company_id/space-management/properties/",
  ADD_AMENITIES:
    "companies/:company_id/space-management/property-amenities/",
  SM_CATEGORIES: "companies/:company_id/space-management/amenity-categories/",
  UPDATE_AMENITY:
    "companies/:company_id/space-management/property-amenity/",
  UPDATE_SORT_ORDER: "companies/:company_id/space-management/property-amenities/sort-order"
};
