import api from '../../assets/api.js';

const types = {
	SET_OMNI_SEARCH: 'SET_OMNI_SEARCH',
	SHOW_CONTACT: 'SHOW_CONTACT',
	SET_OVERLAY: 'SET_OVERLAY',
	SET_LEAD_INTAKE: 'SET_LEAD_INTAKE',
	SET_NAV_STATE: 'SET_NAV_STATE',
	CONTACT_FORM_CHANGED: 'CONTACT_FORM_CHANGED',
	SET_COMPONENT_NAME: 'SET_COMPONENT_NAME',
	SET_COMPONENT_PROPS: 'SET_COMPONENT_PROPS',
	SET_SETTING_NAV_ONLY : 'SET_SETTING_NAV_ONLY',
	SET_APP_SELECTED: 'SET_APP_SELECTED',
	SET_ACTIVE_DRAWER_COMPONENT: 'SET_ACTIVE_DRAWER_COMPONENT',
	SET_SELECTED_PROPERTIES: 'SET_SELECTED_PROPERTIES'
}

class Navigation {
	constructor() {
		this.namespaced = true;
		this.state = {
			isOpen: false, 
			SOComponentName: null,
			SOComponentProps: {},
			hasOmniSearch: false,
			hasLeadIntake: false,
			hasActiveContact: false,
			hasOverlay: false,
			activeContact: false,
			contactFormChanged: false,
			showSettingsNavigationOnly : false,
			isPlatformAppSelected: null,
			activeDrawerComponent: {},
			selectedProperties: []
		};
		this.getters = {
			hasOmniSearch: state => state.hasOmniSearch,
			hasLeadIntake: state => state.hasLeadIntake,
			hasActiveContact: state => state.hasActiveContact,
			hasOverlay: state => state.hasOverlay,
			isOpen: state => state.isOpen,
			isSlideOutOpen: state => state.isSlideOutOpen,
			slideOutComponent: state => state.SOComponentName,
			slideOutProps: state => state.SOComponentProps,
			activeContact: state => state.activeContact,
			flyoutActiveView: state => state.flyoutActiveView,
			contactFormChanged: state => state.contactFormChanged,
			showSettingsNavigationOnly: state => state.showSettingsNavigationOnly,
			platformAppSelected: state => state.isPlatformAppSelected,
			activeDrawerComponent: state => ({...state.activeDrawerComponent}),
			selectedProperties: state => state.selectedProperties
		};
		this.actions = {
			setOmniSearch({commit}, payload){
				commit(types.SET_OMNI_SEARCH, payload);
			},
			showContact({commit}, payload){
				commit(types.SET_COMPONENT_NAME, 'contact');
				commit(types.SET_COMPONENT_PROPS, payload);
			},
			setOverlay({commit}, payload){
				commit(types.SET_OVERLAY, payload);
			},
			setLeadIntake({commit}, payload){
				commit(types.SET_LEAD_INTAKE, payload);
			},
			openSlideOut({commit}, payload){
				commit(types.SET_COMPONENT_NAME, payload.component);
				commit(types.SET_COMPONENT_PROPS, payload.props);
			},
			closeSlideOut({commit}){
				commit(types.SET_COMPONENT_NAME, null);
				commit(types.SET_COMPONENT_PROPS, {});			
			},
			contactFormChanged({commit}, payload){
				commit(types.CONTACT_FORM_CHANGED, payload);
			},
			setSettingsNavigationOnly({commit}, payload){
				commit(types.SET_SETTING_NAV_ONLY, payload);
			},
			setAppLauncher({commit}, payload) {
				commit(types.SET_APP_SELECTED, payload)
			},
			setActiveDrawerComponent({commit}, payload) {
				commit(types.SET_ACTIVE_DRAWER_COMPONENT, payload)
			},
			setSelectedProperties({commit}, payload) {
				commit(types.SET_SELECTED_PROPERTIES, payload)
			}
		};
		this.mutations = {
			[types.SET_OMNI_SEARCH](state, payload) {
				state.hasOmniSearch = payload;
			},
			[types.SET_COMPONENT_NAME](state, payload){
				state.SOComponentName = payload;
			},
			[types.SET_COMPONENT_PROPS](state, payload){
				state.SOComponentProps = payload;
			},
			[types.SET_OVERLAY](state, payload){
				state.hasOverlay = payload;
			},
			[types.SET_LEAD_INTAKE](state, payload){
				state.hasLeadIntake = payload;
			},
			[types.CONTACT_FORM_CHANGED](state, payload){
				state.contactFormChanged = payload;
			},
			[types.SET_SETTING_NAV_ONLY](state, payload){
				state.showSettingsNavigationOnly = payload;
			},
			[types.SET_APP_SELECTED](state, payload){
				state.isPlatformAppSelected = payload;
			},
			[types.SET_ACTIVE_DRAWER_COMPONENT](state, payload){
				state.activeDrawerComponent = payload;
			},
			[types.SET_SELECTED_PROPERTIES](state, payload){
				state.selectedProperties = payload;
			}
		};
	}
}

export default Navigation;